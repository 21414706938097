import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from 'components/layout';
import Loading from 'components/shared/Loading';
import { useSelector } from 'react-redux';

import ErrorBoundary from 'components/shared/ErrorBoundary';
import ErrorPage from 'components/pages/Error';

// TODO: split this up into multiple routes files, every page could have it's own subroutes switch

const LockerUnitList = lazy(() => import('components/pages/LockerUnits'));
const MasterConfiguration = lazy(() =>
  import('components/pages/LockerUnits/Configuration')
);
const EpdUnitList = lazy(() => import('components/pages/EpdUnits'));

const AccountList = lazy(() => import('components/pages/Accounts'));
const AccountCreate = lazy(() =>
  import('components/pages/Accounts/Form/AccountCreate')
);
const AccountModify = lazy(() =>
  import('components/pages/Accounts/Form/AccountModify')
);

const EndUsers = lazy(() => import('components/pages/EndUsers'));
const EndUserModify = lazy(() =>
  import('components/pages/EndUsers/Form/EndUserModify')
);
const EndUserDevices = lazy(() =>
  import('components/pages/EndUsers/DeviceList')
);
const EndUserGroups = lazy(() => import('components/pages/EndUsers/GroupList'));

const GroupList = lazy(() => import('components/pages/Groups'));
const KeyList = lazy(() => import('components/pages/Keys'));
const Authorizations = lazy(() => import('components/pages/Authorizations'));
const PinManagementList = lazy(() => import('components/pages/PinManagement'));

const AlertLogs = lazy(() => import('components/pages/AlertLogs'));
const AlertText = lazy(() => import('components/pages/AlertText'));
const AlertTypeList = lazy(() => import('components/pages/AlertTypes'));
const AlertTypeModify = lazy(() =>
  import('components/pages/AlertTypes/Form/AlertTypeModify')
);

const NetworkConfigurationsList = lazy(() =>
  import('components/pages/NetworkConfigs')
);
const NetworkConfigurationsModify = lazy(() =>
  import('components/pages/NetworkConfigs/Form/NetworkConfigurationsModify')
);
const NetworkConfigurationsCreate = lazy(() =>
  import('components/pages/NetworkConfigs/Form/NetworkConfigurationsCreate')
);

const CourierList = lazy(() => import('components/pages/Couriers/CourierList'));
const CourierForm = lazy(() => import('components/pages/Couriers/Form'));

const CustomerList = lazy(() =>
  import('components/pages/Customers/CustomerList')
);
const CustomerForm = lazy(() => import('components/pages/Customers/Form'));

const CollectorList = lazy(() =>
  import('components/pages/Collectors/CollectorList')
);
const CollectorForm = lazy(() => import('components/pages/Collectors/Form'));

const MailCodesList = lazy(() =>
  import('components/pages/MailCodes/MailCodesList')
);
const Statistics = lazy(() => import('components/pages/Statistics'));

export const RouteWrapper = ({ children }) => (
  <Suspense fallback={<Loading />}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Suspense>
);

const ProtectedRoute = ({ role, path, children, ...rest }) => {
  const currentUser = useSelector((state) => state.auth.current);

  return (
    <Route
      path={path}
      {...rest}
      render={() => {
        const currentRole = currentUser.role;
        if (role === currentRole || currentRole === 'SUPER_ADMIN') {
          return children;
        } else {
          return (
            <RouteWrapper>
              <ErrorPage notFound />
            </RouteWrapper>
          );
        }
      }}
    />
  );
};

const Routes = () => {
  const role = useSelector((state) => state.auth.current.role);
  const currentProject = process.env.REACT_APP_PROJECT;
  let defaultUnitType = 'mpl';
  if (currentProject === 'smartboxbasel' || currentProject === 'flexicpl')
    defaultUnitType = 'cloud';
  if (currentProject === 'flexiretail') defaultUnitType = 'retail';
  return (
    <Router>
      <Layout>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <RouteWrapper>
                <LockerUnitList unitType={defaultUnitType} />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/mpl-units"
            render={() => (
              <RouteWrapper>
                <LockerUnitList unitType="mpl" />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/spl-units"
            render={() => (
              <RouteWrapper>
                <LockerUnitList unitType="spl" />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/tablet-units"
            render={() => (
              <RouteWrapper>
                <LockerUnitList unitType="tablet" />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/cloud-units"
            render={() => (
              <RouteWrapper>
                <LockerUnitList unitType="cloud" />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/cpl-units"
            render={() => (
              <RouteWrapper>
                <LockerUnitList unitType="cpl" />
              </RouteWrapper>
            )}
          />
          {defaultUnitType === 'retail' && (
            <Route
              exact
              path="/retail-lockers"
              render={() => (
                <RouteWrapper>
                  <LockerUnitList unitType="retail" />
                </RouteWrapper>
              )}
            />
          )}
          <Route
            exact
            path="/master/modify/:id"
            render={() => (
              <RouteWrapper>
                <MasterConfiguration />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/end-user"
            render={() => (
              <RouteWrapper>
                <EndUsers />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/end-user/modify/:id"
            render={() => (
              <RouteWrapper>
                <EndUserModify />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/end-user/devices/:id"
            render={() => (
              <RouteWrapper>
                <EndUserDevices />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/end-user/groupMember/:id"
            render={() => (
              <RouteWrapper>
                <EndUserGroups />
              </RouteWrapper>
            )}
          />

          <Route
            exact
            path="/keys"
            render={() => (
              <RouteWrapper>
                <KeyList />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/keys/:id"
            render={() => (
              <RouteWrapper>
                <KeyList />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/alert-logs"
            render={() => (
              <RouteWrapper>
                <AlertLogs />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/alert-types/modify/:id"
            render={() => (
              <RouteWrapper>
                <AlertTypeModify />
              </RouteWrapper>
            )}
          />

          <Route
            exact
            path="/couriers"
            render={() => (
              <RouteWrapper>
                <CourierList />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/couriers/modify/:id"
            render={() => (
              <RouteWrapper>
                <CourierForm isModify />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/couriers/new"
            render={() => (
              <RouteWrapper>
                <CourierForm />
              </RouteWrapper>
            )}
          />
          <Route
            exact
            path="/accounts/modify/:id"
            render={() => (
              <RouteWrapper>
                <AccountModify />
              </RouteWrapper>
            )}
          />

          <Route
            exact
            path="/collectors"
            render={() => (
              <RouteWrapper>
                <CollectorList />
              </RouteWrapper>
            )}
          />

          {/* RESTRICTED ROUTES */}
          {/* ADMIN RESTRICTED ROUTES */}
          <ProtectedRoute exact role="ADMIN" path="/groups">
            <RouteWrapper>
              <GroupList />
            </RouteWrapper>
          </ProtectedRoute>
          <ProtectedRoute exact role="ADMIN" path="/network-configurations">
            <RouteWrapper>
              <NetworkConfigurationsList />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute
            exact
            role="ADMIN"
            path="/network-configurations/modify/:id"
          >
            <RouteWrapper>
              <NetworkConfigurationsModify />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="ADMIN" path="/network-configurations/new">
            <RouteWrapper>
              <NetworkConfigurationsCreate />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="ADMIN" path="/mail-codes">
            <RouteWrapper>
              <MailCodesList />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="ADMIN" path="/statistics">
            <RouteWrapper>
              <Statistics />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute
            exact
            role="ADMIN"
            path="/statistics/:timePeriod/:timeRange/:deviceList"
          >
            <RouteWrapper>
              <Statistics />
            </RouteWrapper>
          </ProtectedRoute>

          {/* SUPER_ADMIN ROUTES */}
          <ProtectedRoute exact role="SUPER_ADMIN" path="/epd-units">
            <RouteWrapper>
              <EpdUnitList />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/authorizations">
            <RouteWrapper>
              <Authorizations />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/pin-management">
            <RouteWrapper>
              <PinManagementList />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/alerts">
            <RouteWrapper>
              <AlertText />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/alert-types">
            <RouteWrapper>
              <AlertTypeList />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/customers">
            <RouteWrapper>
              <CustomerList />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/customers/modify/:id">
            <RouteWrapper>
              <CustomerForm isModify />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/customers/new">
            <RouteWrapper>
              <CustomerForm />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute
            exact
            role="SUPER_ADMIN"
            path="/collectors/modify/:id"
          >
            <RouteWrapper>
              <CollectorForm isModify />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/collectors/new">
            <RouteWrapper>
              <CollectorForm />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/accounts">
            <RouteWrapper>
              <AccountList />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/accounts/new">
            <RouteWrapper>
              <AccountCreate />
            </RouteWrapper>
          </ProtectedRoute>

          <ProtectedRoute exact role="SUPER_ADMIN" path="/accounts/modify/:id">
            <RouteWrapper>
              <AccountModify />
            </RouteWrapper>
          </ProtectedRoute>

          {/* FALLBACK 404 */}
          <Route
            render={() => (
              <RouteWrapper>
                <ErrorPage notFound />
              </RouteWrapper>
            )}
          />
        </Switch>
      </Layout>
    </Router>
  );
};

export default Routes;
