/* eslint-disable arrow-body-style */

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => {
  return value ? undefined : 'This field is required';
};

export const checkUnique = (array) => (value) =>
  value && array.includes(value) ? 'This field must be unique' : undefined;

export const regularCharacters = (value) => {
  return !/^[A-Za-z0-9_]*$/i.test(value)
    ? 'This field may only contain regular letters A-Z, a-z, the _ sign, or digits 0-9'
    : undefined;
};

export const password = (value) => {
  return value &&
    !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=\-_!])(?=\S+$).{6,}$/.test(
      value
    )
    ? `Password must be at least 6 characters long with no spaces and
  at least one digit,
  lower case letter,
  upper case letter and
   special character`
    : undefined;
};

export const email = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
};

export const multipleEmails = (value) => {
  return value &&
    !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/i.test(
      value
    )
    ? `Invalid email address(es). When entering multiple email addresses, use ; as a separator.`
    : undefined;
};

export const cron = (value) => {
  return value && !/((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/i.test(value)
    ? `Invalid cron expression`
    : undefined;
};

export const integer = (value) => {
  return value && !/^[0-9]*$/.test(value)
    ? 'This must be a whole number'
    : undefined;
};

export const groupName = (toValidate) => {
  let groupNameNonSpaceLength = 0;
  toValidate.split('').forEach((char) => {
    if (char !== ' ') groupNameNonSpaceLength += 1;
  });
  if (groupNameNonSpaceLength > 30) {
    return `Group name contains more than 30 characters.`;
  }

  const words = toValidate.split(' ');
  if (words.some((word) => word.length > 15)) {
    return `Group name contains words longer than 15 characters.`;
  }

  const rows = ['', ''];
  const isRowError = words.some((word) => {
    let currentRow = 0;
    // go to next row if the current one will overfill (15 + 1 for EOL space)
    while (`${rows[currentRow]}${word} `.length > 16 && currentRow < 2)
      currentRow += 1;
    // if it goes beyond the second row, set isRowError to false
    if (currentRow > 1) return true;
    // in the clear: append the current word to the current row (with a space)
    rows[currentRow] += `${word} `;
    return false;
  });
  if (isRowError) return `Group name will not fit e-paper dimensions`;
};
