import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const END_USER_FETCH_REQUEST = 'endUsers/END_USER_FETCH_REQUEST';
export const END_USER_FETCH_SUCCESS = 'endUsers/END_USER_FETCHED_SUCCESS';
export const END_USER_FETCH_ERROR = 'endUsers/END_USER_FETCHED_ERROR';

export const END_USERS_FETCH_REQUEST = 'endUsers/END_USERS_FETCH_REQUEST';
export const END_USERS_FETCH_SUCCESS = 'endUsers/END_USERS_FETCHED_SUCCESS';
export const END_USERS_FETCH_ERROR = 'endUsers/END_USERS_FETCHED_ERROR';

export const END_USER_CREATE_REQUEST = 'endUsers/END_USER_CREATE_REQUEST';
export const END_USER_CREATE_SUCCESS = 'endUsers/END_USER_CREATE_SUCCESS';
export const END_USER_CREATE_ERROR = 'endUsers/END_USER_CREATE_ERROR';

export const END_USER_MODIFY_REQUEST = 'endUsers/END_USER_MODIFY_REQUEST';
export const END_USER_MODIFY_SUCCESS = 'endUsers/END_USER_MODIFY_SUCCESS';
export const END_USER_MODIFY_ERROR = 'endUsers/END_USER_MODIFY_ERROR';

export const END_USER_UNLOAD_CURRENT = 'endUsers/END_USER_UNLOAD_CURRENT';

export const CHANGE_USER_VALID_STATUS_REQUEST =
  'endUsers/CHANGE_USER_VALID_STATUS_REQUEST';
export const CHANGE_USER_VALID_STATUS_SUCCESS =
  'endUsers/CHANGE_USER_VALID_STATUS_SUCCESS';
export const CHANGE_USER_VALID_STATUS_ERROR =
  'endUsers/CHANGE_USER_VALID_STATUS_ERROR';

export const END_USER_ACTIVATE_REQUEST = 'endUsers/END_USER_ACTIVATE_REQUEST';
export const END_USER_ACTIVATE_SUCCESS = 'endUsers/END_USER_ACTIVATE_SUCCESS';
export const END_USER_ACTIVATE_ERROR = 'endUsers/END_USER_ACTIVATE_ERROR';

export const END_USER_DEACTIVATE_REQUEST =
  'endUsers/END_USER_DEACTIVATE_REQUEST';
export const END_USER_DEACTIVATE_SUCCESS =
  'endUsers/END_USER_DEACTIVATE_SUCCESS';
export const END_USER_DEACTIVATE_ERROR = 'endUsers/END_USER_DEACTIVATE_ERROR';

export const END_USER_DELETE_REQUEST = 'endUsers/END_USER_DELETE_REQUEST';
export const END_USER_DELETE_SUCCESS = 'endUsers/END_USER_DELETE_SUCCESS';
export const END_USER_DELETE_ERROR = 'endUsers/END_USER_DELETE_ERROR';

export const END_USER_PARSE_CSV_REQUEST = 'endUsers/END_USER_PARSE_CSV_REQUEST';
export const END_USER_PARSE_CSV_SUCCESS = 'endUsers/END_USER_PARSE_CSV_SUCCESS';
export const END_USER_PARSE_CSV_ERROR = 'endUsers/END_USER_PARSE_CSV_ERROR';

export const END_USER_IMPORT_REQUEST = 'endUsers/END_USER_IMPORT_REQUEST';
export const END_USER_IMPORT_SUCCESS = 'endUsers/END_USER_IMPORT_SUCCESS';
export const END_USER_IMPORT_ERROR = 'endUsers/END_USER_IMPORT_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
    importUsers: null,
    updating: false,
  },
  action
) {
  switch (action.type) {
    case END_USER_IMPORT_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case END_USERS_FETCH_REQUEST:
    case END_USER_FETCH_REQUEST:
    case END_USER_PARSE_CSV_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case END_USERS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.endUsers,
        result: action.payload.result,
      };
    case END_USER_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };
    case END_USER_PARSE_CSV_SUCCESS:
      return {
        ...state,
        fetching: false,
        importUsers: action.payload,
      };
    case END_USERS_FETCH_ERROR:
    case END_USER_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        importUsers: null,
      };

    case END_USER_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };

    case CHANGE_USER_VALID_STATUS_SUCCESS: {
      const userId = action.id;
      const { newValidStatus } = action;
      const list = { ...state.entities };

      if (userId in list) {
        list[userId].valid = newValidStatus;
      }

      const newCurrent = { ...state.current };
      if (state.current && state.current.id === userId) {
        newCurrent.valid = newValidStatus;
      }

      return {
        ...state,
        current: newCurrent,
        entities: list,
      };
    }

    case END_USER_IMPORT_SUCCESS:
    case END_USER_IMPORT_ERROR:
      return {
        ...state,
        updating: false,
      };
    default:
      return state;
  }
}

// Schemas
const endUserSchema = new schema.Entity('endUsers');

// Operations
export function fetchEndUsers(params) {
  return {
    [CALL_API]: {
      types: [
        END_USERS_FETCH_REQUEST,
        END_USERS_FETCH_SUCCESS,
        END_USERS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/endUser', params),
      schema: { data: [endUserSchema] },
    },
  };
}

export function fetchEndUser(id) {
  return function (dispatch, getState) {
    const endUsersState = getState().endUsers;

    if (endUsersState.entities && id in endUsersState.entities) {
      // endUser already exists in store
      dispatch({
        type: END_USER_FETCH_SUCCESS,
        payload: endUsersState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            END_USER_FETCH_REQUEST,
            END_USER_FETCH_SUCCESS,
            END_USER_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/endUser/${id}`),
        },
      });
    }
  };
}

export function fetchEndUserAlways(id) {
  return function (dispatch) {
    dispatch({
      [CALL_API]: {
        types: [
          END_USER_FETCH_REQUEST,
          END_USER_FETCH_SUCCESS,
          END_USER_FETCH_ERROR,
        ],
        promise: (httpClient) => httpClient.get(`/endUser/${id}`),
      },
    });
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: END_USER_UNLOAD_CURRENT });
  };
}

export function modifyEndUser(endUser, onSuccess) {
  return {
    [CALL_API]: {
      types: [
        END_USER_MODIFY_REQUEST,
        END_USER_MODIFY_SUCCESS,
        END_USER_MODIFY_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/endUser/updateEndUserData', endUser),
      successMessage: 'End user successfully updated',
      onSuccess,
    },
  };
}

export function activateEndUser(id) {
  return {
    [CALL_API]: {
      types: [
        END_USER_ACTIVATE_REQUEST,
        END_USER_ACTIVATE_SUCCESS,
        END_USER_ACTIVATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/endUser/activate', { id }),
      successMessage: 'End user successfully activated',
    },
  };
}

export function deactivateEndUser(id) {
  return {
    [CALL_API]: {
      types: [
        END_USER_DEACTIVATE_REQUEST,
        END_USER_DEACTIVATE_SUCCESS,
        END_USER_DEACTIVATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/endUser/deactivate', { id }),
      successMessage: 'End user is scheduled to be deactivated',
    },
  };
}

export function deleteEndUser(id) {
  return {
    [CALL_API]: {
      types: [
        END_USER_DELETE_REQUEST,
        END_USER_DELETE_SUCCESS,
        END_USER_DELETE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/endUser/hardDelete', { id }),
      successMessage: 'End user is scheduled to be deleted',
    },
  };
}

export function parseUserCSV(data) {
  return {
    [CALL_API]: {
      types: [
        END_USER_PARSE_CSV_REQUEST,
        END_USER_PARSE_CSV_SUCCESS,
        END_USER_PARSE_CSV_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/endUser/import/cpl/parse/csv', data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
    },
  };
}

export function importUsers({
  errorMessage,
  successMessage,
  userList,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [
        END_USER_IMPORT_REQUEST,
        END_USER_IMPORT_SUCCESS,
        END_USER_IMPORT_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('endUser/import/cpl/import/users', userList),
      successMessage: successMessage || 'End users successfully imported',
      errorMessage: errorMessage || 'There was a problem importing those users',
      onSuccess,
    },
  };
}
