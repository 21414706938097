import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core/styles';
import { Paper, Button, Box } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';

import brands from 'brands';
import { loginUser } from 'redux/modules/auth';
import { required } from 'utils/form-validators';
import Loading from 'components/shared/Loading';

const { theme, backgroundImage, logoLogin } = brands[
  process.env.REACT_APP_PROJECT
];

const Container = styled.div`
  min-width: 320px;
  max-width: 400px;
  height: auto;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
`;

const StyledPaper = styled(Paper)`
  padding: 20px !important;
  overflow: auto !important;
`;

const LogoContainer = styled.div`
  text-align: center;
`;

// todo: additional assets could be handled more generically
let blockLogo;
let mapBg;
if (process.env.REACT_APP_PROJECT === 'smartboxbasel') {
  blockLogo = require('assets/images/smartboxbasel/logo_block.png');
  mapBg = require('assets/images/smartboxbasel/map_bg.png');
}
const smartboxBaselBackground = css`
  background: url(${blockLogo}), url(${mapBg});
  background-size: 475px, 600px;
  background-repeat: no-repeat, no-repeat;
  background-position: top 11% left 1px, bottom 0 right 17px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  min-width: 100vw;
  background-image: url(${backgroundImage});
  background-size: cover;
  z-index: -9999;

  ${process.env.REACT_APP_PROJECT === 'smartboxbasel' &&
  smartboxBaselBackground}
`;

// special styling for the smartbox basel horizontal logo
// todo: revisit this for horizontal or vertical logos if necessary
const LogoImage = styled.img`
  width: ${process.env.REACT_APP_PROJECT === 'smartboxbasel' ? '90%' : '50%'};
  margin: ${process.env.REACT_APP_PROJECT === 'smartboxbasel'
    ? '2rem 0 1rem'
    : '0'};
`;

const StyledSubmitButton = styled(Button)`
  float: right;
  width: 120px;
`;

const CollapsibleBox = styled(Box)`
  max-height: ${(props) => (props.isOpen ? 'fit-content' : 0)};
  overflow: hidden;
  transition: max-height 0.3s;
`;

export default function LoginPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticating = useSelector((state) => state.auth.authenticating);
  const [is2FaEnabled, setIs2FaEnabled] = useState(false);
  const TwoFaField = useRef(null);

  const onSubmit = async ({ username, password, twoFactorAuthCode }) => {
    dispatch(
      loginUser({
        username,
        password,
        twoFactorAuthCode,
        onUnauthorized: () => setIs2FaEnabled(true),
      })
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Background />
        <StyledPaper className="main-content login-window">
          <LogoContainer>
            <LogoImage src={logoLogin} />
          </LogoContainer>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  validate={required}
                  component={TextField}
                  name="username"
                  label={t('username')}
                  fullWidth
                  data-cy="login-input-username"
                />
                <Field
                  validate={required}
                  component={TextField}
                  name="password"
                  label={t('password')}
                  fullWidth
                  type="password"
                  data-cy="login-input-password"
                />
                <CollapsibleBox isOpen={is2FaEnabled}>
                  <Field
                    // todo: add a validator here
                    // validate={is2FaEnabled ? required : null}
                    component={TextField}
                    name="twoFactorAuthCode"
                    label={t('twoFactorAuthCode')}
                    fullWidth
                    data-cy="login-input-2fa"
                    ref={TwoFaField}
                  />
                  <div style={{ margin: 'auto auto 0.9rem', color: 'grey' }}>
                    <small>
                      {t`For 2FA, you need an Authenticator App. You can use Google Authenticator or other free and trusted alternatives, available on all mobile app stores.`}
                    </small>
                  </div>
                </CollapsibleBox>
                <div>
                  <StyledSubmitButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isAuthenticating}
                  >
                    {isAuthenticating ? <Loading isButton /> : t('login')}
                  </StyledSubmitButton>
                </div>
              </form>
            )}
          />
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}
