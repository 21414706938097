import huberTheme from './themes/huberTheme';
import huberImages from './assets/images/huber';

import zwickTheme from './themes/zwickTheme';
import zwickImages from './assets/images/zwick';

import flexilockerTheme from './themes/flexilockerTheme';
import flexilockerImages from './assets/images/flexilocker';

import smartboxbaselTheme from './themes/smartboxbaselTheme';
import smartboxbaselImages from './assets/images/smartboxbasel';

import cleverTheme from './themes/cleverTheme';
import cleverImages from './assets/images/clever';

export const brandList = {
  HUBER: 'huber',
  ZWICK: 'zwick',
  FLEXILOCKER: 'flexilocker',
  FLEXIRETAIL: 'flexiretail',
  FLEXICPL: 'flexicpl',
  SMARTBOXBASEL: 'smartboxbasel',
  CLEVER: 'clever',
};

const brands = {
  huber: {
    name: 'Schlauebox',
    theme: huberTheme,
    ...huberImages,
  },

  zwick: {
    name: 'ZWICKbox',
    theme: zwickTheme,
    ...zwickImages,
  },

  flexilocker: {
    name: 'Flexilocker',
    theme: flexilockerTheme,
    ...flexilockerImages,
  },
  flexiretail: {
    name: 'Flexilocker Retail',
    theme: flexilockerTheme,
    ...flexilockerImages,
  },
  flexicpl: {
    name: 'Flexilocker CPL',
    theme: flexilockerTheme,
    ...flexilockerImages,
  },
  smartboxbasel: {
    name: 'SmartBoxBasel',
    theme: smartboxbaselTheme,
    ...smartboxbaselImages,
  },
  clever: {
    name: 'CleverKiosk',
    theme: cleverTheme,
    ...cleverImages,
  },
};

export default brands;

export const brand = brands[process.env.REACT_APP_PROJECT];
