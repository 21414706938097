import React from 'react';
import {
  MdViewQuilt,
  MdInbox,
  MdTabletAndroid,
  MdCloud,
  MdLocalShipping,
  MdLocationCity,
  MdToc,
  MdPerson,
  MdGroup,
  MdVpnKey,
  MdVerifiedUser,
  MdLock,
  MdLibraryBooks,
  MdMessage,
  MdNotifications,
  MdNetworkCell,
  MdGroupWork,
  MdAccountBox,
  MdLocationOn,
} from 'react-icons/md';
import { FaHandsHelping } from 'react-icons/fa';
import { IoMdStats } from 'react-icons/io';
import { brandList } from './brands';

const iconStyle = {
  fontSize: 21,
};

const menuList = [
  {
    text: 'mpl_units',
    icon: <MdViewQuilt style={iconStyle} />,
    link: '/mpl-units',
    projects: [brandList.HUBER, brandList.ZWICK, brandList.FLEXILOCKER],
  },
  {
    text: 'retail_lockers',
    icon: <MdViewQuilt style={iconStyle} />,
    link: '/retail-lockers',
    projects: [brandList.FLEXIRETAIL],
  },
  {
    text: 'spl_units',
    icon: <MdInbox style={iconStyle} />,
    link: '/spl-units',
    projects: [brandList.HUBER, brandList.ZWICK, brandList.FLEXILOCKER],
  },
  {
    text: 'tablet_units',
    icon: <MdTabletAndroid style={iconStyle} />,
    link: '/tablet-units',
    projects: [brandList.HUBER, brandList.ZWICK, brandList.FLEXILOCKER],
  },
  {
    text: 'cloud_units',
    icon: <MdCloud style={iconStyle} />,
    link: '/cloud-units',
    projects: [...Object.values(brandList)],
  },
  {
    text: 'cpl_units',
    icon: <MdLocationCity style={iconStyle} />,
    link: '/cpl-units',
    projects: [brandList.SMARTBOXBASEL, brandList.FLEXICPL],
  },
  {
    text: 'epd_units',
    icon: <MdToc style={iconStyle} />,
    link: '/epd-units',
    restrict: ['SUPER_ADMIN'],
    projects: [brandList.HUBER, brandList.ZWICK, brandList.FLEXILOCKER],
  },
  {
    text: 'end_users',
    icon: <MdPerson style={iconStyle} />,
    link: '/end-user',
    projects: [...Object.values(brandList)],
  },
  {
    text: 'mail_codes',
    icon: <MdLocationOn style={iconStyle} />,
    link: '/mail-codes',
    projects: [brandList.SMARTBOXBASEL, brandList.FLEXICPL],
  },
  {
    text: 'groups',
    icon: <MdGroup style={iconStyle} />,
    link: '/groups',
    projects: [...Object.values(brandList)],
  },
  {
    text: 'keys',
    icon: <MdVpnKey style={iconStyle} />,
    link: '/keys',
    projects: [...Object.values(brandList)],
  },
  {
    text: 'authorizations',
    icon: <MdVerifiedUser style={iconStyle} />,
    link: '/authorizations',
    restrict: ['SUPER_ADMIN'],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'pin_management',
    icon: <MdLock style={iconStyle} />,
    link: '/pin-management',
    restrict: ['SUPER_ADMIN'],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'Alert logs',
    icon: <MdLibraryBooks style={iconStyle} />,
    link: '/alert-logs',
    projects: [...Object.values(brandList)],
  },
  {
    text: 'Alert text',
    icon: <MdMessage style={iconStyle} />,
    link: '/alerts',
    restrict: ['SUPER_ADMIN'],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'Alert types',
    icon: <MdNotifications style={iconStyle} />,
    link: '/alert-types',
    restrict: ['SUPER_ADMIN'],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'network_configurations',
    icon: <MdNetworkCell style={iconStyle} />,
    link: '/network-configurations',
    restrict: ['SUPER_ADMIN', 'ADMIN'],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'courier_list',
    icon: <MdLocalShipping style={iconStyle} />,
    link: '/couriers',
    restrict: ['SUPER_ADMIN', 'ADMIN'],
    // projects: [brandList.SMARTBOXBASEL, brandList.FLEXICPL, brandList.HUBER],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'collector_list',
    icon: <FaHandsHelping style={iconStyle} />,
    link: '/collectors',
    // restrict: ''
    // projects: [brandList.SMARTBOXBASEL, brandList.FLEXICPL],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'customer_list',
    icon: <MdGroupWork style={iconStyle} />,
    link: '/customers',
    restrict: ['SUPER_ADMIN'],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'accounts',
    icon: <MdAccountBox style={iconStyle} />,
    link: '/accounts',
    restrict: ['SUPER_ADMIN'],
    projects: [...Object.values(brandList)],
  },
  {
    text: 'statistics',
    icon: <IoMdStats style={iconStyle} />,
    link: '/statistics',
    projects: [...Object.values(brandList)],
  },
];

export default menuList;
