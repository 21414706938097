import wordmark from './wordmark.png';

const images = {
  logoLogin: wordmark,
  logo: '',
  wordmark,
  favicon: '',
};

export default images;
