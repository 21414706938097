import { CALL_API } from '../middleware/api';

// ACTIONS

export const CANNED_FETCH_REQUEST = 'canned/CANNED_FETCH_REQUEST';
export const CANNED_FETCH_SUCCESS = 'canned/CANNED_FETCH_SUCCESS';
export const CANNED_FETCH_ERROR = 'canned/CANNED_FETCH_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case CANNED_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case CANNED_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case CANNED_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Operations

export function fetchCanned(businessId) {
  return {
    [CALL_API]: {
      types: [CANNED_FETCH_REQUEST, CANNED_FETCH_SUCCESS, CANNED_FETCH_ERROR],
      promise: (httpClient) =>
        httpClient.get(`/master/${businessId}/canned/list`),
    },
  };
}
