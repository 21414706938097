import axios from 'axios';
import { API_URL } from '../config';

const httpClient = axios.create({
  baseURL: API_URL,
  timeout: 20000,
  withCredentials: true,
});

// Setup axios
httpClient.defaults.withCredentials = true;
httpClient.interceptors.response.use(null, (err) => Promise.reject(err));

export default httpClient;
