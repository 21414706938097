import { css } from 'styled-components/macro';

export default css`
  body {
    background-color: #ebebeb;
  }

  b {
    font-weight: bold;
  }

  .clear {
    clear: both;
  }

  .centerAlign {
    text-align: center;
  }

  .rt-tr {
    position: relative;
  }

  .ReactTable .rt-tbody .rt-td[data-show-visible='true'] {
    position: relative;
    overflow: visible;
  }

  .ReactTable .rt-tbody .rt-td {
    line-height: 1.8rem !important;
  }

  .notes-table .rt-td {
    white-space: normal !important;
  }

  .mui-table td,
  .mui-table th {
    vertical-align: middle;
  }

  /* Blinking animation */
  @keyframes blink {
    from {
      opacity: 1;
    }

    to {
      opacity: 0.01;
    }
  }

  span.blink {
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-duration: 1s;
  }

  .mplDetails > div > div {
    max-width: none !important;
    width: 85% !important;
  }

  .deleteDialog > div > div {
    max-width: 450px !important;
  }

  .mplDetails {
    padding-top: 10px !important;
  }

  .outerDiv > div > div {
    width: 240px !important;
  }

  @media only screen and (max-width: 1400px) {
    .outerDiv > div > div {
      width: 220px !important;
    }

    .autocomplete {
      width: 190px !important;
    }

    .configurationDisplay {
      width: 220px !important;
      word-wrap: break-word !important;
    }

    .configurationDisplay > p {
      word-break: break-all;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    .outerDiv > div > div {
      width: 170px !important;
    }

    .autocomplete {
      width: 170px !important;
    }

    .configurationDisplay {
      width: 170px !important;
      word-wrap: break-word !important;
    }

    .configurationDisplay > p {
      word-break: break-all;
    }

    .masterConfigField {
      width: 240px !important;
    }
  }

  @media only screen and (max-width: 451px) {
    .pageBase > div > div {
      float: none !important;
      margin-bottom: 15px !important;
      padding-left: 10px !important;
    }

    .pageBase > div > h3 {
      margin-bottom: 0px !important;
    }

    .lfUUXV {
      width: 95px !important;
    }

    .appBar > div {
      margin-right: 0px !important;
    }

    .sc-gzVnrw > a > button {
      min-width: 48px !important;
    }
  }

  /* 
  @media only screen and (max-width: 414px) {
    .outerDiv {
      padding-left: 30px !important;
      max-width: 100%;
    }
    .right {
      margin: initial !important;
      padding-right: 60px !important;
    }
    .autocomplete {
      float: right !important;
    }
    .left {
      float: none !important;
    }
    .lePPqA {
      max-width: 30%;
      right: 15px;
    }
    .alertType_email > label {
      font-size: 14px !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .leftDrawer>div {
      background-color: #6f6f6f !important;
    }
  }

  .tabItems>div>button {
    color: white !important;
    text-shadow: 1px 1px black;
    margin-right: 1px !important;
    /* TODO: Edit this colour */
  /* background-color: rgb(211, 166, 0) !important;
  } */

  .UserAccessibilityContainer .col-xs-6 {
    padding-left: 0px;
  }
`;
