import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/core/styles';
import brands from '../../brands';

// import logoImg from '../assets/images/logo.png';
import packageJson from '../../../package.json';

const { theme, logo } = brands[process.env.REACT_APP_PROJECT];
const { drawerTextColor } = theme;
const appVersion = packageJson.version;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 230px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.12), 1px 1px 4px rgba(0, 0, 0, 0.12);
    ${(props) =>
      props.open
        ? `
      background-color: transparent;
      background-image: none;
    `
        : ``}
    ${(props) =>
      props.isSmallScreen
        ? `background: ${props.theme.palette.background.default};`
        : ''}
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border: none;
  }
`;

const LogoContainer = styled.div`
  height: 106px;
  background-color: transparent;
  text-align: center;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  max-width: 85%;
  max-height: 90px;
`;

const StyledLink = styled(Link)`
  color: ${drawerTextColor};
  text-decoration: none;

  .MuiMenuItem-root {
    font-size: 14px;
    height: 42px;
    line-height: 42px;

    svg {
      margin: 0 16px 0 0;
    }
  }
`;

const Version = styled.p`
  font-size: 0.7rem;
  display: block;
  color: ${drawerTextColor};
  opacity: 0.3;
  bottom: 0.5rem;
  right: 0.5rem;
  position: absolute;
`;

const LeftDrawer = ({ isOpen, isSmallScreen, onToggle, menus }) => {
  const themeHook = useTheme();

  return (
    <StyledDrawer
      theme={themeHook}
      className="leftDrawer"
      variant={isSmallScreen ? 'temporary' : 'persistent'}
      onClose={onToggle}
      anchor="left"
      open={isOpen}
      isSmallScreen={isSmallScreen}
      data-cy="nav-menu"
    >
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>
      <div style={{ textTransform: 'capitalize' }}>
        {menus.map((menu, index) => (
          <StyledLink to={menu.link} key={menu.link}>
            <MenuItem key={index}>
              {menu.icon}
              {menu.text}
            </MenuItem>
          </StyledLink>
        ))}
      </div>
      <Version>{appVersion}</Version>
    </StyledDrawer>
  );
};

LeftDrawer.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any.isRequired,
      icon: PropTypes.any,
      link: PropTypes.string.isRequired,
    })
  ),
};

export default LeftDrawer;
