import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const LOCKERS_FETCH_REQUEST = 'lockers/LOCKERS_FETCH_REQUEST';
export const LOCKERS_FETCH_SUCCESS = 'lockers/LOCKERS_FETCH_SUCCESS';
export const LOCKERS_FETCH_ERROR = 'lockers/LOCKERS_FETCH_ERROR';

export const LOCKER_MODIFY_REQUEST = 'lockers/LOCKER_MODIFY_REQUEST';
export const LOCKER_MODIFY_SUCCESS = 'lockers/LOCKER_MODIFY_SUCCESS';
export const LOCKER_MODIFY_ERROR = 'lockers/LOCKER_MODIFY_ERROR';

export const MASTER_FETCH_REQUEST = 'lockers/MASTER_FETCH_REQUEST';
export const MASTER_FETCH_SUCCESS = 'lockers/MASTER_FETCH_SUCCESS';
export const MASTER_FETCH_ERROR = 'lockers/MASTER_FETCH_ERROR';

export const LOCKER_FETCH_REQUEST = 'lockers/LOCKER_FETCH_REQUEST';
export const LOCKER_FETCH_SUCCESS = 'lockers/LOCKER_FETCH_SUCCESS';
export const LOCKER_FETCH_ERROR = 'lockers/LOCKER_FETCH_ERROR';

export const EPD_PREVIEW_REQUEST = 'lockers/EPD_PREVIEW_REQUEST';
export const EPD_PREVIEW_SUCCESS = 'lockers/EPD_PREVIEW_SUCCESS';
export const EPD_PREVIEW_ERROR = 'lockers/EPD_PREVIEW_ERROR';

export const LOCKER_CHANGE_STATUS_REQUEST =
  'lockers/LOCKER_CHANGE_STATUS_REQUEST';
export const LOCKER_CHANGE_STATUS_SUCCESS =
  'lockers/LOCKER_CHANGE_STATUS_SUCCESS';
export const LOCKER_CHANGE_STATUS_ERROR = 'lockers/LOCKER_CHANGE_STATUS_ERROR';

export const LOCKER_UNLOAD_CURRENT = 'lockers/LOCKER_UNLOAD_CURRENT';

export const FIRMWARE_FETCH_REQUEST = 'lockers/FIRMWARE_FETCH_REQUEST';
export const FIRMWARE_FETCH_SUCCESS = 'lockers/FIRMWARE_FETCH_SUCCESS';
export const FIRMWARE_FETCH_ERROR = 'lockers/FIRMWARE_FETCH_ERROR';

export const FIRMWARE_UPDATE_REQUEST = 'lockers/FIRMWARE_UPDATE_REQUEST';
export const FIRMWARE_UPDATE_SUCCESS = 'lockers/FIRMWARE_UPDATE_SUCCESS';
export const FIRMWARE_UPDATE_ERROR = 'lockers/FIRMWARE_UPDATE_ERROR';

export const DISPLAY_NAME_UPDATE_REQUEST =
  'lockers/DISPLAY_NAME_UPDATE_REQUEST';
export const DISPLAY_NAME_UPDATE_SUCCESS =
  'lockers/DISPLAY_NAME_UPDATE_SUCCESS';
export const DISPLAY_NAME_UPDATE_ERROR = 'lockers/DISPLAY_NAME_UPDATE_ERROR';

export const LOCKER_DATA_REQUEST = 'lockers/LOCKER_DATA_REQUEST';
export const LOCKER_DATA_SUCCESS = 'lockers/LOCKER_DATA_SUCCESS';
export const LOCKER_DATA_ERROR = 'lockers/LOCKER_DATA_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
    image: null,
    currentFirmware: null,
    fetchingFirmware: false,
  },
  action
) {
  switch (action.type) {
    case LOCKERS_FETCH_REQUEST:
    case LOCKER_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case LOCKER_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case LOCKERS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.lockers,
        result: action.payload.result,
      };

    case EPD_PREVIEW_SUCCESS:
      return {
        ...state,
        image: action.payload,
      };

    case LOCKERS_FETCH_ERROR:
    case LOCKER_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case LOCKER_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };

    case LOCKER_CHANGE_STATUS_SUCCESS: {
      const newCurrent = { ...state.current };
      newCurrent.splIsActivated = !!action.newState;

      return {
        ...state,
        current: newCurrent,
      };
    }

    case FIRMWARE_FETCH_REQUEST: {
      return {
        ...state,
        currentFirmware: null,
        fetchingFirmware: true,
      };
    }

    case FIRMWARE_FETCH_SUCCESS: {
      return {
        ...state,
        currentFirmware: action.payload,
        fetchingFirmware: false,
      };
    }

    case FIRMWARE_FETCH_ERROR: {
      return {
        ...state,
        fetchingFirmware: false,
      };
    }

    default:
      return state;
  }
}

// Schemas
const lockerSchema = new schema.Entity('lockers');

// Operations
export function fetchLockers(params) {
  return {
    [CALL_API]: {
      types: [
        LOCKERS_FETCH_REQUEST,
        LOCKERS_FETCH_SUCCESS,
        LOCKERS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/locker', params),
      schema: { data: [lockerSchema] },
    },
  };
}

export function fetchLocker(id) {
  return function (dispatch, getState) {
    dispatch({
      [CALL_API]: {
        types: [LOCKER_FETCH_REQUEST, LOCKER_FETCH_SUCCESS, LOCKER_FETCH_ERROR],
        promise: (httpClient) => httpClient.get(`/master/${id}`),
      },
    });
  };
}

export function getEPDPreview(id) {
  return {
    [CALL_API]: {
      types: [EPD_PREVIEW_REQUEST, EPD_PREVIEW_SUCCESS, EPD_PREVIEW_ERROR],
      promise: (httpClient) => httpClient.get(`/master/${id}/epdPreview`),
      // successMessage: 'Image gotten',
    },
  };
}

export function modifyLocker(locker) {
  return {
    [CALL_API]: {
      types: [
        LOCKER_MODIFY_REQUEST,
        LOCKER_MODIFY_SUCCESS,
        LOCKER_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.put(`/master/update/data`, locker),
      successMessage: 'Unit successfully modified',
    },
  };
}

export function modifyAlertsEnabled({
  id,
  value,
  successMessage = 'Alerts enabled set',
  errorMessage = 'There was a problem settings alerts enabled on this device',
}) {
  return function (dispatch, getState) {
    const state = getState();
    const locker = state?.mpls?.entities[id];

    dispatch({
      [CALL_API]: {
        types: [
          LOCKER_MODIFY_REQUEST,
          LOCKER_MODIFY_SUCCESS,
          LOCKER_MODIFY_ERROR,
        ],
        promise: (httpClient) =>
          httpClient.post(`/master/modify`, {
            ...locker,
            alertsEnabled: value,
          }),
        successMessage,
        errorMessage,
      },
    });
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: LOCKER_UNLOAD_CURRENT });
  };
}

export function changeActivationStatus(id, newState, endUserId) {
  return {
    id,
    newState,
    [CALL_API]: {
      types: [
        LOCKER_CHANGE_STATUS_REQUEST,
        LOCKER_CHANGE_STATUS_SUCCESS,
        LOCKER_CHANGE_STATUS_ERROR,
      ],
      promise: (httpClient) =>
        newState === false
          ? httpClient.get(`/master/spl/${id}/deactivate`)
          : httpClient.get(`/master/spl/${id}/activate/${endUserId}`),
      successMessage: 'Locker status changed',
    },
  };
}

export function fetchStationFirmware(masterId) {
  return {
    [CALL_API]: {
      types: [
        FIRMWARE_FETCH_REQUEST,
        FIRMWARE_FETCH_SUCCESS,
        FIRMWARE_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.get(`/firmware/versions/${masterId}`),
    },
  };
}

export function updateFirmwareVersion(masterId, stmFirmwareId, ninaFirmwareId) {
  return {
    [CALL_API]: {
      types: [
        FIRMWARE_UPDATE_REQUEST,
        FIRMWARE_UPDATE_SUCCESS,
        FIRMWARE_UPDATE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.get(
          `/firmware/version/update/${masterId}/${stmFirmwareId}/${ninaFirmwareId}`
        ),
      successMessage: 'Successfully set firmware targets',
    },
  };
}

export function updateDisplayName({
  displayName,
  successMessage,
  errorMessage,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [
        DISPLAY_NAME_UPDATE_REQUEST,
        DISPLAY_NAME_UPDATE_SUCCESS,
        DISPLAY_NAME_UPDATE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.patch(
          '/masterConfiguration/update/displayName',
          displayName
        ),
      successMessage: successMessage || 'Display name successfully updated',
      errorMessage: errorMessage || 'There was a problem updating display name',
      onSuccess: onSuccess || (() => null),
    },
  };
}

export function fetchLockerToGetMasterId(id) {
  return function (dispatch, getState) {
    dispatch({
      [CALL_API]: {
        types: [LOCKER_DATA_REQUEST, LOCKER_DATA_SUCCESS, LOCKER_DATA_ERROR],
        promise: (httpClient) => httpClient.get(`/locker/${id}`),
      },
    });
  };
}
