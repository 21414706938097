import { schema } from 'normalizr';
import omit from 'lodash.omit';
import { CALL_API } from '../middleware/api';
import { AFTER_HOOK } from '../middleware/hookHandler';

// ACTIONS

export const MASTER_CONFIGURATIONS_FETCH_REQUEST =
  'masterConfigurations/MASTER_CONFIGURATIONS_FETCH_REQUEST';
export const MASTER_CONFIGURATIONS_FETCH_SUCCESS =
  'masterConfigurations/MASTER_CONFIGURATIONS_FETCH_SUCCESS';
export const MASTER_CONFIGURATIONS_FETCH_ERROR =
  'masterConfigurations/MASTER_CONFIGURATIONS_FETCH_ERROR';

export const MASTER_CONFIGURATION_FETCH_REQUEST =
  'masterConfigurations/MASTER_CONFIGURATION_FETCH_REQUEST';
export const MASTER_CONFIGURATION_FETCH_SUCCESS =
  'masterConfigurations/MASTER_CONFIGURATION_FETCH_SUCCESS';
export const MASTER_CONFIGURATION_FETCH_ERROR =
  'masterConfigurations/MASTER_CONFIGURATION_FETCH_ERROR';

export const MASTER_CONFIGURATION_CREATE_REQUEST =
  'masterConfigurations/MASTER_CONFIGURATION_CREATE_REQUEST';
export const MASTER_CONFIGURATION_CREATE_SUCCESS =
  'masterConfigurations/MASTER_CONFIGURATION_CREATE_SUCCESS';
export const MASTER_CONFIGURATION_CREATE_ERROR =
  'masterConfigurations/MASTER_CONFIGURATION_CREATE_ERROR';

export const MASTER_CONFIGURATION_MODIFY_REQUEST =
  'masterConfigurations/MASTER_CONFIGURATION_MODIFY_REQUEST';
export const MASTER_CONFIGURATION_MODIFY_SUCCESS =
  'masterConfigurations/MASTER_CONFIGURATION_MODIFY_SUCCESS';
export const MASTER_CONFIGURATION_MODIFY_ERROR =
  'masterConfigurations/MASTER_CONFIGURATION_MODIFY_ERROR';

export const MASTER_CONFIGURATION_DELETE_REQUEST =
  'masterConfigurations/MASTER_CONFIGURATION_DELETE_REQUEST';
export const MASTER_CONFIGURATION_DELETE_SUCCESS =
  'masterConfigurations/MASTER_CONFIGURATION_DELETE_SUCCESS';
export const MASTER_CONFIGURATION_DELETE_ERROR =
  'masterConfigurations/MASTER_CONFIGURATION_DELETE_ERROR';

export const MASTER_CONFIGURATION_UNLOAD_CURRENT =
  'masterConfigurations/MASTER_CONFIGURATION_UNLOAD_CURRENT';

export const MASTER_CONFIGURATION_ASSIGN_REQUEST =
  'masterConfigurations/MASTER_CONFIGURATION_ASSIGN_REQUEST';
export const MASTER_CONFIGURATION_ASSIGN_SUCCESS =
  'masterConfigurations/MASTER_CONFIGURATION_ASSIGN_SUCCESS';
export const MASTER_CONFIGURATION_ASSIGN_ERROR =
  'masterConfigurations/MASTER_CONFIGURATION_ASSIGN_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case MASTER_CONFIGURATIONS_FETCH_REQUEST:
    case MASTER_CONFIGURATION_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case MASTER_CONFIGURATION_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case MASTER_CONFIGURATION_DELETE_SUCCESS:
      const updatedEntities = omit(state.entities, action.id);
      return {
        ...state,
        entities: updatedEntities,
      };

    case MASTER_CONFIGURATIONS_FETCH_SUCCESS:
      if (action.payload.data !== undefined) {
        return {
          ...state,
          fetching: false,
          fetched: false,
          entities: action.payload.data,
          result: action.payload.result,
        };
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.masterConfigurations,
        result: action.payload.result,
      };

    case MASTER_CONFIGURATIONS_FETCH_ERROR:
    case MASTER_CONFIGURATION_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case MASTER_CONFIGURATION_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}

// Schemas
const masterConfigurationSchema = new schema.Entity('masterConfigurations');

// Operations
export function fetchMasterConfigurations(params) {
  return {
    [CALL_API]: {
      types: [
        MASTER_CONFIGURATIONS_FETCH_REQUEST,
        MASTER_CONFIGURATIONS_FETCH_SUCCESS,
        MASTER_CONFIGURATIONS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/masterConfiguration', params),
      schema: { data: [masterConfigurationSchema] },
    },
  };
}

export function fetchMasterConfiguration(id) {
  return function (dispatch, getState) {
    const masterConfigurationsState = getState().masterConfigurations;
    if (
      masterConfigurationsState.entities &&
      id in masterConfigurationsState.entities
    ) {
      // masterConfiguration already exists in store
      dispatch({
        type: MASTER_CONFIGURATION_FETCH_SUCCESS,
        payload: masterConfigurationsState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            MASTER_CONFIGURATION_FETCH_REQUEST,
            MASTER_CONFIGURATION_FETCH_SUCCESS,
            MASTER_CONFIGURATION_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/masterConfiguration/${id}`),
        },
      });
    }
  };
}

export function modifyMasterConfiguration(masterConfiguration) {
  return {
    [CALL_API]: {
      types: [
        MASTER_CONFIGURATION_MODIFY_REQUEST,
        MASTER_CONFIGURATION_MODIFY_SUCCESS,
        MASTER_CONFIGURATION_MODIFY_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/masterConfiguration/modify', masterConfiguration),
      successMessage: 'Unit successfully modified',
    },
  };
}

export function createMasterConfiguration({
  masterConfigs,
  successMessage = 'Access configurations successfully created',
}) {
  return function (dispatch) {
    dispatch({
      [CALL_API]: {
        types: [
          MASTER_CONFIGURATION_CREATE_REQUEST,
          MASTER_CONFIGURATION_CREATE_SUCCESS,
          MASTER_CONFIGURATION_CREATE_ERROR,
        ],
        promise: (httpClient) => {
          const promises = masterConfigs.map((masterConfig) =>
            httpClient.post('/masterConfiguration/modify', masterConfig)
          );
          return Promise.all(promises);
        },
        successMessage,
      },
      [AFTER_HOOK]: (storeApi, action) => {
        if (action.type === MASTER_CONFIGURATION_CREATE_SUCCESS) {
          dispatch(fetchMasterConfigurations());
        }
      },
    });
  };
}

export function deleteMasterConfiguration(ids) {
  return function (dispatch) {
    dispatch({
      [CALL_API]: {
        types: [
          MASTER_CONFIGURATION_DELETE_REQUEST,
          MASTER_CONFIGURATION_DELETE_SUCCESS,
          MASTER_CONFIGURATION_DELETE_ERROR,
        ],
        promise: (httpClient) => {
          const promises = ids.map((id) =>
            httpClient.delete(`/masterConfiguration/${id}/delete`)
          );
          return Promise.all(promises);
        },
        successMessage: 'Access configuration successfully deleted',
      },
      [AFTER_HOOK]: (storeApi, action) => {
        if (action.type === MASTER_CONFIGURATION_DELETE_SUCCESS) {
          dispatch(fetchMasterConfigurations());
        }
      },
    });
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: MASTER_CONFIGURATION_UNLOAD_CURRENT });
  };
}

// this is for lockers that don't have buttons (tablet and others without EPDs)
// ☝️ in that case the configs are assigned to the button
export function assignMasterConfigs({
  masterConfigs,
  successMessage = 'Access configurations successfully assigned',
  errorMessage = 'There was a problem assigning the access configurations',
}) {
  return function (dispatch) {
    dispatch({
      [CALL_API]: {
        types: [
          MASTER_CONFIGURATION_ASSIGN_REQUEST,
          MASTER_CONFIGURATION_ASSIGN_SUCCESS,
          MASTER_CONFIGURATION_ASSIGN_ERROR,
        ],
        promise: (httpClient) => {
          const promises = masterConfigs.map((config) =>
            httpClient.post('/masterConfiguration/assignGroup', config)
          );
          return Promise.all(promises);
        },
        successMessage,
        errorMessage,
      },
      [AFTER_HOOK]: (storeApi, action) => {
        if (action.type === MASTER_CONFIGURATION_ASSIGN_SUCCESS) {
          dispatch(fetchMasterConfigurations());
        }
      },
    });
  };
}
